.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--space, var(--size-800));
}

.stack > * {
  margin-block: 0;
}

@media (--s-viewport) {
  .stack {
    gap: var(--space, var(--size-600));
  }
}
