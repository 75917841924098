.splitter > :last-child {
  /* margin-top: var(--size-500); */
}

@media (--s-viewport) {
  .splitter {
  }
}

@media @include media-query('md') {
  .splitter {
    display: flex;

    > * {
      flex-grow: 1;
    }

    > :last-child {
      margin-top: 0;
      margin-left: var(--size-500);
      min-width: 22rem;
    }
  }
}
