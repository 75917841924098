.button {
  border: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-hl);
  padding: var(--size-300) var(--size-600);
  width: max-content;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--size-500);
}
