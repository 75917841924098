#header {
  padding-top: var(--size-800);
  padding-bottom: var(--size-800);
}

#header .logo {
  height: 60px;
}

#header .wrapper {
  align-items: center;
  justify-content: space-between;
  --space: var(--size-300);
}

.site-header-navigation {
  --space: var(--size-400);
  list-style: none;
  padding: 0;
}

.site-header-navigation a {
  text-decoration: none;
  transition: 0.2s ease color;
}

.site-header-navigation a:hover {
  color: var(--color-hl);
}

@media (--s-viewport) {
  #header {
    padding-top: var(--size-600);
    padding-bottom: var(--size-600);
  }

  #header .logo {
    flex: 1 1 auto;
    max-width: 280px;
    height: auto;
  }

  #header > .wrapper {
    flex-flow: row;
  }
}
