#hero {
  min-height: 85vh;
  position: relative;
  overflow: hidden;
  overflow: clip;
}

#hero .hero-illustration {
  position: absolute;
  width: 60%;
  max-width: 800px;
  bottom: 0;
  right: 0;
  transform: translateX(20%);
  z-index: 10;
}

#hero .hexagon {
  position: absolute;
  width: 86%;
  bottom: 0;
  right: 0;
  max-width: 1058px;
  transform: translate(34%, 41%);
}

#hero .wrapper {
  z-index: 2;
}

@media (--s-viewport) {
  #hero {
    /* min-height: 0; */
    justify-content: flex-start;
  }
}
