html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-sans);
  color: var(--color-fg);
  background-color: var(--color-bg);
}

h1,
h2,
h3 {
}

h1 {
  font-size: var(--size-900);
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  color: var(--color-hl);
  font-weight: bold;
  text-transform: uppercase;
  font-size: var(--size-800);
}

h3 {
  font-size: var(--size-700);
}

h4 {
  font-size: var(--size-600);
}

p {
  max-width: 40ch;
}

p.big {
  font-size: var(--size-500);
}

a {
}

table {
}

th {
}

blockquote {
  font-size: 3rem;
  padding-left: var(--size-800);
  padding-right: var(--size-800);
  text-align: center;
  position: relative;
}

blockquote:before {
  content: " ";
  position: absolute;
  left: 0;
  background-image: url("../public/img/quote-left.png");
  background-repeat: no-repeat;
  width: 63px;
  height: 55px;
  background-size: 100% 100%;
}

blockquote:after {
  position: absolute;
  top: 0;
  right: 0;
  content: " ";
  width: 63px;
  height: 55px;
  background-image: url("../public/img/quote-right.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

main > section {
  min-height: 70vh;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: var(--size-1000);
  padding-bottom: var(--size-1000);
}

:focus {
}

@media (--m-viewport) {
  blockquote {
    font-size: 2rem;
  }
}

@media (--s-viewport) {
  h1 {
    font-size: var(--size-800);
  }

  h2 {
    font-size: var(--size-700);
  }

  h3 {
    font-size: var(--size-600);
  }

  h4 {
    font-size: var(--size-500);
  }

  blockquote {
    font-size: 1.5rem;
    padding-left: var(--size-300);
    padding-right: var(--size-300);
  }

  blockquote:before {
    width: 30px;
    height: 25px;
  }

  blockquote:after {
    width: 30px;
    height: 25px;
  }
  main > section {
    padding-top: var(--size-800);
    padding-bottom: var(--size-800);
  }
}
