#investors {
  background-color: var(--color-neutral-02);
  color: var(--color-neutral-04);
}

#investors .wrapper {
}

#investors img {
  max-width: 200px;
}

#investors header {
  text-align: center;
}

#investors .cluster {
  margin: auto;
  align-items: center;
  justify-content: center;
}

@media (--s-viewport) {
  #investors img {
    max-width: 180px;
  }
}
