#header .menu-header {
  padding-top: var(--size-600);
  padding-bottom: var(--size-600);
  flex-flow: row;
}

.menu-button {
  flex: 0 0 auto;
}

.icon-button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--color-fg);
  transition: color 0.2s ease;
}

.icon-button svg {
  width: 34px;
  height: 34px;
}

.icon-button:hover {
  color: var(--color-hl);
}

@media (--s-viewport) {
  #header .site-header-navigation-wrapper {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-bg);
    z-index: 100;
    font-size: var(--size-600);
    font-weight: bold;
    text-transform: uppercase;
  }

  #header[data-open] .site-header-navigation-wrapper {
    display: block;
  }

  .site-header-navigation {
    display: flex;
    flex-flow: column;
    padding-left: var(--size-300);
    padding-right: var(--size-300);
  }
}
