:root {
  --border-width: 1px;
  --border-radius: 0rem;
  --border-color: #ddd;
  --border: var(--border-width) solid var(--border-color);
}

.border {
  border: var(--border);
  border-radius: var(--border-radius);
}

.border-bottom {
  border-bottom: var(--border);
}
