:root {
  --color-neutral-01: #fff;
  --color-neutral-02: #efeeee;
  --color-neutral-03: #959595;
  --color-neutral-04: #1d1d1b;
  --color-neutral-05: #000;

  --color-primary-01: #ff6358;

  --color-bg: var(--color-neutral-05);
  --color-bg-alt: var(--color-neutral-04);
  --color-fg: var(--color-neutral-01);
  --color-fg-alt: var(--color-neutral-02);
  --color-hl: var(--color-primary-01);
}

/* :root { */
/*   --color-primary-01: #e0d0ff; */
/*   --color-primary-02: #683090; */
/*   --color-primary-03: #5800a8; */
/*   --color-primary-04: #481868; */
/* } */
