#technology {
  background-color: var(--color-neutral-01);
  color: var(--color-neutral-04);
}

#technology img {
  max-width: 180px;
}

#technology header {
  text-align: center;
}

#technology .cluster {
  margin: auto;
  align-items: center;
  justify-content: center;
}

@media (--s-viewport) {
  #technology img {
    max-width: 180px;
  }
}
