#contact {
  background-color: var(--color-primary-01);
  color: var(--color-neutral-01);
}

#contact header {
  text-align: center;
}

#contact header > * {
  color: var(--color-neutral-01);
}

#contact header p {
}

#contact p {
  font-size: var(--size-600);
}

#contact .wrapper {
  align-items: center;
  justify-content: center;
  text-align: center;
}
