#testimonials {
  background-color: var(--color-neutral-01);
  color: var(--color-neutral-03);
}

#testimonials .cluster {
  align-items: center;
  justify-content: center;
}

#testimonials .cluster:before {
  content: "";
  align-self: stretch;
  border: 1px solid var(--border-color);
}

#testimonials img {
  max-width: 200px;
}

#testimonials .cluster > *:first-child {
  order: -1;
}

@media (--s-viewport) {
  #testimonials .cluster {
    flex-flow: column;
  }
}
