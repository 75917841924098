#awards {
}

#awards header {
  text-align: center;
}

#awards header h2 {
  color: var(--color-fg);
}

#awards .cluster {
  margin: auto;
  align-items: center;
  justify-content: center;
}

.award {
  --space: 4px;
  width: 180px;
  min-height: 180px;
  align-items: center;
  justify-content: flex-start;
  padding-top: var(--size-800);
  text-align: center;
  background-image: url("../public/img/laurel.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.award-category {
  max-width: 10ch;
  font-size: 0.8em;
}
