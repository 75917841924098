#the-team {
  background-color: var(--color-neutral-02);
  color: var(--color-neutral-04);
}

#the-team header {
  text-align: center;
}

#the-team article {
  flex: 1;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
}

#the-team article img {
  width: 50%;
  margin: 0 auto;
}

#the-team .team-wrapper {
  margin: auto;
}

#the-team .cluster {
  width: 100%;
  justify-content: space-between;
}

#the-team .cluster:before {
  content: "";
  align-self: stretch;
  border: 1px dashed var(--border-color);
}

#the-team .cluster > *:first-child {
  order: -1;
}

@media (--s-viewport) {
  #the-team .cluster {
    flex-flow: column;
  }
  #the-team article img {
    width: 80%;
  }
}
