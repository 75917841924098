#landing-page {
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  text-transform: uppercase;
  --space: var(--size-300);
}

#landing-page .logo {
  width: 100%;
  height: auto;
  max-width: 500px;
}
