@font-face {
  font-family: "Favorit";
  font-weight: normal;
  src: url("../../public/fonts/Favorit-Book.woff2") format("woff2"),
    url("../../public/fonts/Favorit-Book.woff") format("woff");
}

@font-face {
  font-family: "Favorit";
  font-weight: bold;
  src: url("../../public/fonts/Favorit-Bold.woff2") format("woff2"),
    url("../../public/fonts/Favorit-Bold.woff") format("woff");
}

:root {
  --font-sans: "Favorit", sans-serif;
}
