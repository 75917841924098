.wrapper {
  width: 100%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--size-800);
}

@media (--s-viewport) {
  .wrapper {
    padding: 0 var(--size-400);
  }
}
